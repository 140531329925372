.social-row {
  align-items: center;
  display: flex;
  height: 18px;
  position: relative;
  flex-wrap: wrap;
}

.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--diesel);
  border: 1px solid #ccc;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}