.quote {
  align-items: flex-start;
  display: inline-flex;
  justify-content: flex-start;
  min-height: 61px;
  min-width: 320px;
  /* position: absolute;
  left: 10px;
  top: 184px; */
}

.quote-text {
  color: var(--polar);
  line-height: 17px;
  min-height: 61px;
  text-align: center;
  width: 320px;
  flex-direction: row;
}

.quote-span {
  font-family: var(--font-family-arial-italicmt);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  line-height: 22px;
  flex-basis: auto;
}