.sign-in-button__container {
    display: flex;
    align-items: stretch;
    height: 40px;
    justify-content: center;
    min-width: 67px;
    cursor: pointer;
  }
  
  .sign-in-button {
    align-items: center;
    justify-content: center;
    background-color: var(--anakiwa);
    border-radius: 4px;
    display: flex;
    height: 42px;
    margin-top: -1.0px;
    min-width: 67px;
    padding: 0 18.0px;
    cursor: pointer;
  }
  
  .sign-in-button__label {
    color: #131415;
    font-weight: 700;
    line-height: 28px;
    min-height: 28px;
    min-width: 67px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
  }
  
  