.add-project-container {
    background-color: var(--diesel);
    display: flex;
    flex-direction: column;
    width: 300px;
}

.add-project-close-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--diesel);
}

.add-project-title {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-21px);
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
}

.add-project-description {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}

.add-project-radio-container {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 20px;
}

.add-project-input-radio {
  display: flex;
  align-self: center;
}

input[type=radio] {
  margin: 0px 10px 0px 0px;
}

.add-project-radio-description {
  display: flex;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  align-self: center;
  vertical-align: middle;
}

.add-project-search {
  font-family: var(--font-family-inter);
  width: 100%;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #666666;
  text-align: left;
  border-radius: 2px;
  margin: 7px 0px 30px 0px;
  min-height: 23px;
}

.add-project-url-choose-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.add-project-url-choose-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 128px;
  height: 128px;
}

.add-project-url-choose-img {
  display: flex;
  flex-direction: row;
  width: 128px;
  height: 128px;
  object-fit: scale-down;
}

.add-project-editor {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.add-project-url-choose-image-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.close-button-container {
    display: flex;
    justify-content: flex-end;
}

.command-button-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 25px 0px 0px 0px;
  }

  .command-button {
    margin: 0px 25px;
  }
  
.add-project-choice-container {
  display: flex;
  flex-direction: row;
}

.add-project-activity-image {
  display: flex;
  justify-content: center;
}