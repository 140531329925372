.card-avatar-and-bio {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 340px;
  position: relative;
  width: 340px;
  margin-bottom: 20px;
}

.image-container {
  display: flex;
  flex-direction: row;
}

.image-avatar {
  border: 1px solid var(--anakiwa);
  height: 400px;
  object-fit: cover;
  width: 400px;
}

.edit-icon {
  align-self: end;
  margin: 0px 0px 0px 7px;
  cursor: pointer;
}



/* @media (max-width: 740px) {
  .bio-container-large-screen {
    display: none;
  }
} */

