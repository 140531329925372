.stacks-wallet {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 44px;
  justify-content: space-between;
  /* position: absolute;
  left: 0;
  top: 278px;
  width: 340px; */
}

.edit-icon-nft-buttons {
  align-self: center;
  /* margin: 12px 12px; */
  cursor: pointer;
}

.nft-button-container {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 0px 0px;
}

.nft-button {
  margin: 0px 10px 0px 10px;
}

.stx-id-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stx-id-label {
  display: flex;
  align-self: center;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  margin: 0px 10px
}

.stx-id {
  display: flex;
  align-self: center;
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  margin: 0px 10px
}

.stx-id-copy {
  display: flex;
}

.stx-id-copy-img {
  align-self: center;
  /* margin: 12px 12px; */
  cursor: pointer;
}