.card-user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 322px;
  /* margin-left: 172px; */
  position: relative;
  width: 400px;
  margin: 0px 12px 0px 12px;
}

.social-stack {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* position: absolute;
  left: 44px;
  top: 101px; */
  width: 252px;
}

.bio-container {
  display: inline;
}