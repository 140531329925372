.faviconsandyorange {
  align-items: flex-end;
  background-color: transparent;
  display: flex;
}

.sandy-2006-no-bg {
  height: 48px;
  width: 48px;
}

