.portfolio-widget-collection-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.portfolio-widget-collection-items-container {
    display: flex;
    flex-direction: row;
    align-items: left;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
}

.add-portfolio-widget-button {
    width: auto;
}

.portfolio-widget {
    width: auto;
}