body {
  background-color: var(--eerie-black);
  color: #e9e9e9;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: auto;
  width: 100%;
}

.main__center {
  display: flex;
  flex-direction: column;
  background-color: var(--eerie-black);
  position: relative;
  /* max-width: 1440px; */
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  justify-content: center;
  align-self: center;
  margin-top: 100px;
}

.user-info-cards-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 425px;
  /* position: relative; */
  align-self: center;
  justify-content: space-around;
}

@media (max-width: 740px) {
  .content {
    margin-top: 20px;
  }
  .user-info-cards-container {
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}

.project-widget-collection {
  margin-top: 60px;
  position: relative;
  width: 100%;
}

.portfolio-widget-collection {
  margin-top: 60px;
  position: relative;
  width: 100%;
}