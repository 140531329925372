.project-widget-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    padding: 10px;
}

.project-widget-edit-controls-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    height: 120px;
    padding: 0px;
}

.edit-icon-project-widget {
    align-self: center;
    /* margin: 12px 12px; */
    cursor: pointer;
    margin-bottom: 10px;
  }

  .delete-icon-project-widget {
    align-self: center;
    /* margin: 12px 12px; */
    cursor: pointer;
    margin-bottom: 10px;
    width: 20px;
  }

.project-widget-content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
}

.project-widget-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;
    padding: 0px;
}

.project-widget-image {
    display: flex;
    max-width: 128px;
    max-height: 128px;
    padding: 10px;
}

.project-widget-title-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 170px;
    height: 150px;
}

.project-widget-title {
    color: var(--white);
    font-weight: 700;
    line-height: 28px;
    white-space: nowrap;
    margin: 10px;
    flex-wrap: wrap;
    font-family: var(--font-family-sfprotext-bold);
    font-size: var(--font-size-15px);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-widget-description {
    color: var(--white);
    line-height: 15px;
    margin: 10px;
    min-height: 15px;
    flex-wrap: wrap;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-11px);
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-project-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--diesel);
    border: 1px solid #ccc;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;   
}