@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Inter:700,500");
@font-face {
  font-family: "SFProText-Medium";
  font-weight: 500;
  src: url("static/fonts/FontsFree-Net-SFProText-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SFProText-Bold";
  font-weight: 700;
  src: url("static/fonts/SF-Pro-Text-Bold.otf") format("opentype");
}
@font-face {
  font-family: "SFProText-Regular";
  font-weight: 400;
  src: url("static/fonts/SF-Pro-Text-Regular.otf") format("opentype");
}

/* mobile responsive breakpoint 700px  */

.screen a {
  display: contents;
  text-decoration: none;
}

.full-width-a {
  width: 100%;
}

.full-height-a {
  height: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
