.button-container-regular {
    align-items: flex-start;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 67px;
    cursor: pointer;
  }
  
  .group-regular {
    align-items: flex-start;
    justify-content: center;
    border: 2px solid var(--anakiwa);
    border-radius: 4px;
    display: flex;
    height: 42px;
    margin-top: -1.0px;
    min-width: 67px;
    padding: 5.0px 18.0px;
    cursor: pointer;
  }
  
  .label-regular {
    color: var(--anakiwa);
    font-weight: 700;
    line-height: 28px;
    min-height: 28px;
    min-width: 67px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }
  
  