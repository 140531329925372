.social-2 {
  align-items: center;
  display: flex;
  margin-left: 18px;
}

.stacked-group {
  align-items: center;
  display: flex;
  height: 18px;
  position: relative;
  cursor: pointer;
}

.social-handle {
  color: var(--quill-gray);
  line-height: 18px;
  margin-right: 3px;
  white-space: nowrap;
}

.social-2.social-1 {
  margin-left: 12px;
}

.icon-style {
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

